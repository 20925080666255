
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { type DataTableFilterEvent } from 'primereact/datatable';
import { Dock } from 'primereact/dock';
import { MenuItem } from 'primereact/menuitem';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { VehiclesService, type Vehicle } from 'src/apis/api-client';
import { EfCard, EfDataTable } from 'src/shared';
import "./VehiclesDetailsCard.css";



type VehiclesDetailsCardProps = {
  	vehicleId: string;
	name: string;
	totalMileage: number;
	unit: "km" | "mi";
	selected: boolean;
	onDetailsClick: (vehicleId?: string) => any;
};

export function VehiclesDetailsCard({name, totalMileage, unit, vehicleId, selected, onDetailsClick}: VehiclesDetailsCardProps) {

	const [lazyParams, setLazyParams] = useState<DataTableFilterEvent>();
	const [totalRecords, setTotalRecords] = useState(0);

	const { isLoading, data, isError } = useQuery(
		['fooBars'],
		fetchFooBars,
		{ staleTime: 3000 },
	);

	useEffect(() => {
		console.log(`SELECTED for [${vehicleId}]: `, selected);
		console.log(`CLASSLIST ADD for [${vehicleId}]: `, document.querySelector(`vehicles-details-card-${vehicleId}`)?.classList);
		if(selected){
			document.getElementById(`vehicles-details-card-${vehicleId}`)?.classList.remove("vehicles-details-card-unselected");
			document.getElementById(`vehicles-details-card-${vehicleId}`)?.classList.add("vehicles-details-card-selected");
		}else{
			document.getElementById(`vehicles-details-card-${vehicleId}`)?.classList.remove("vehicles-details-card-selected");
			document.getElementById(`vehicles-details-card-${vehicleId}`)?.classList.add("vehicles-details-card-unselected");
		}
		console.log(`CLASSLIST REMOVE for [${vehicleId}]: `, document.querySelector(`vehicles-details-card-${vehicleId}`)?.classList);
	}, [selected]);

	async function fetchFooBars(): Promise<Vehicle[] | undefined> {
		// const response = await FooBarsService.get(lazyParams?.first ?? 0, lazyParams?.rows ?? 10);
		// if (response.result) {
		// 	setTotalRecords(response.totalCount ?? 0);
		// 	return response.result;
		// }

		return undefined;
	}

	return (
		<div className='container'>
			<div id={`vehicles-details-card-${vehicleId}`} 
			className={`vehicles-details-card surface-border border-1 border-round p-3 ${
				selected == true ? 'bg-primary text-surface' : ''
			}`} 
			onClick={
				() => { 
					console.log(`CLICKED for [${vehicleId}]: `, selected)
					onDetailsClick(selected == true ? undefined : vehicleId) 
				}}>
				<div className='flex flex-column gap-5'>
					<img
						style={{height: '100%', width: '100%', objectFit: 'fill', maxHeight: "12rem"}}
						src="https://static-assets.tesla.com/configurator/compositor?context=design_studio_2&options=$MTY09,$PPSW,$WY19B,$INPB0&view=FRONT34&model=my&size=1920&bkba_opt=1&crop=0,0,0,0&"
					/>
					<div className='flex flex-row gap-'>
						<div className='flex flex-column flex-grow-1 gap-2 flex-1' style={{ maxHeight: "5rem" }}>
							<span className='font-bold'>{name}</span>
							<span className='text-xs'>{totalMileage.toLocaleString("de-DE")} {unit}</span>
							{/* <Skeleton height='100%' width='30%' />
							<Skeleton height='100%' width='80%' /> */}
						</div>
						{/* <div className='flex align-items-center justify-content-end'>
							<Button text label="Details" />
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}
