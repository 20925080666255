import {useMsal} from '@azure/msal-react';
import {EfCard} from 'src/shared';

export function UserInfoGeneralCard() {

	const {accounts} = useMsal();
	return (
		<EfCard title="General" isLoading={!accounts}>
			{accounts
				&& accounts.length > 0
				&& accounts[0].idTokenClaims
				&&
				<div className='flex'>
					<div>
						<div>Username: {accounts[0]?.idTokenClaims.preferred_username}</div>
						<div>Name: {accounts[0]?.idTokenClaims.name}</div>
					</div>
				</div>
			}
		</EfCard>
	);
}

export default UserInfoGeneralCard;