import {HomeView} from 'src/domains';
import {Route, Routes} from 'react-router-dom';

export function HomeRouter() {
	return (
		<Routes>
			<Route path={'/*'} element={<HomeView />}></Route>
		</Routes>
	);
}

export default HomeRouter;
