import {Card, type CardProps} from 'primereact/card';
import {ProgressBar} from 'primereact/progressbar';
import {type PropsWithChildren} from 'react';
import {Divider} from 'primereact/divider';

export type EfCardProps = {
	isLoading?: boolean;
	isError?: boolean;
} & CardProps;

export function EfCard(props: PropsWithChildren<EfCardProps>) {
	const {isLoading, isError, ...cardProps} = props;
	return (
		<>
			{ 
				isLoading ?
					<ProgressBar mode="indeterminate" style={{height: '3px'}}></ProgressBar>
					:
					<Divider style={{height: '3px'}} className={'m-0 p-0 ' + (isError  === true ? 'bg-red-600' : 'bg-primary')}/>
			}
			<Card
				{...cardProps}
			>
				{props.children}
			</Card>
		</>
	);
}

export default EfCard;