import {Route, Routes} from 'react-router-dom';
import {LoginView, UserInfoView} from 'src/domains';

export function UsersRouter() {

	return (
		<Routes>
			<Route path={'/'} element={<LoginView />}></Route>
			<Route path={'/user-info'} element={<UserInfoView />}></Route>
		</Routes>
	);
}

export default UsersRouter;