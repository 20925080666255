import { HomeView } from 'src/domains';
import {Route, Routes} from 'react-router-dom';

export function FooBarsRouter() {
	return (
		<Routes>
			<Route path={'/'} element={<HomeView />}></Route>
		</Routes>
	);
}
