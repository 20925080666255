import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useState } from "react";
import "./VehiclesListRangeSelector.css";

export function VehiclesListRangeSelector() {

  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);
  const lastMonth = getLastMonthStartEnd();
  const currentMonth = getCurrentMonthStartEnd();
  const [isLastMonth, setIsLastMonth] = useState<boolean>(false);
  const [isCurrentMonth, setIsCurrentMonth] = useState<boolean>(false);

  useEffect(() => {
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      const newIsLastMonth = getStartOfDay(getLastMonthStartEnd().start).getTime() === getStartOfDay(dates[0]).getTime() && getStartOfDay(getLastMonthStartEnd().end).getTime() === getStartOfDay(dates[1]).getTime();
      setIsLastMonth(newIsLastMonth);
  
      const newIsCurrentMonth = getStartOfDay(getCurrentMonthStartEnd().start).getTime() === getStartOfDay(dates[0]).getTime() && getStartOfDay(getCurrentMonthStartEnd().end).getTime() === getStartOfDay(dates[1]).getTime();
      setIsCurrentMonth(newIsCurrentMonth);
      
    } else {
      setIsLastMonth(false);
      setIsCurrentMonth(false);
    }
  }, [dates]);

  function formatDateRange(dateRange: Nullable<(Date | null)[]>) {
    if (!dates || !dates[0] || !dates[1]) {
      return 'Select Range';
    }
    return `${dates[0].toLocaleDateString('de-DE')} - ${dates[1].toLocaleDateString('de-DE')}`;
  }

  function getStartOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }


  function getLastMonthStartEnd(): { start: Date, end: Date } {

    const now = new Date();

    // First day of the current month
    const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Last day of the previous month
    const lastDayPreviousMonth = new Date(firstDayCurrentMonth);
    lastDayPreviousMonth.setDate(lastDayPreviousMonth.getDate() - 1);

    // First day of the previous month
    const firstDayPreviousMonth = new Date(lastDayPreviousMonth.getFullYear(), lastDayPreviousMonth.getMonth(), 1);
    // Make sure to set the start and end to the start of the day
    return {
      start: getStartOfDay(firstDayPreviousMonth),
      end: getStartOfDay(lastDayPreviousMonth)
    };
  }

  function getCurrentMonthStartEnd(): { start: Date, end: Date } {
    const now = new Date();

    // First day of the current month
    const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Today's date, set to the start of the day
    const today = getStartOfDay(new Date());

    return {
      start: firstDayCurrentMonth,
      end: today
    };
  }

  return <div className='flex align-items-center justify-content-center flex-row gap-4'>
    {/* <div className="flex font-bold">
      <span className='font-bold'>Date Range</span>
    </div> */}
    <Button text={!isLastMonth} raised onClick={() => setDates([lastMonth.start, lastMonth.end])} >Last Month</Button>
    {/* <Chip className={`${isLastMonth ? 'bg-primary': ''} range-chip`} onClick={() => setDates([lastMonth.start, lastMonth.end])} label="Last Month" /> */}
    <Calendar
      value={dates}
      onChange={(e) => setDates(e.value)}
      selectionMode="range"
      readOnlyInput
      placeholder="Select Range"
      className="border-none"
      incrementIcon
      maxDate={new Date()}
    />
    <Button text={!isCurrentMonth} raised onClick={() => setDates([currentMonth.start, currentMonth.end])} >Current Month</Button>
    {/* <Chip className={`${isCurrentMonth ? 'bg-primary': ''} range-chip`} onClick={() => setDates([currentMonth.start, currentMonth.end])} label="Current Month" /> */}
  </div>;
}
