import {AuthPageContainer} from 'src/shared';

export function LoginView() {

	return (
		<AuthPageContainer title='Login'>

		</AuthPageContainer>
	);
}

export default LoginView;