import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AppBar} from 'src/core';
import {FooBarsRouter, HomeRouter, UsersRouter} from 'src/domains';

export function CoreRouter() {
	return (
		<BrowserRouter>
			<AppBar>
				<Routes>
					<Route path='/*' element={<HomeRouter />}></Route>
					<Route path='/foobars/*' element={<FooBarsRouter />}></Route>
					<Route path='/users/*' element={<UsersRouter />}></Route>
				</Routes>
			</AppBar>
		</BrowserRouter>
	);
}

export default CoreRouter;
