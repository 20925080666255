
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dock } from 'primereact/dock';
import { MenuItem } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';
import { VehicleMetricsChargingScatterCard, VehicleMetricsChargingSessionsCard, VehiclesDetailsCard, VehiclesListRangeSelector } from 'src/domains/vehicles';
import { Calendar } from 'primereact/calendar';
import { useState } from 'react';
import { Nullable } from 'primereact/ts-helpers';
import { Divider } from 'primereact/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./VehiclesListCarousel.css";
import { Vehicle } from '@/apis/api-client';
import { TabPanel, TabView } from 'primereact/tabview';

export function VehiclesListCarousel() {

	const [selectedVehicleId, setSelectedVehicleId] = useState<string | undefined>();

	const vehicles: Vehicle[] = [
		{
			data: {
				id: "1",
				name: "The Real Slim Speedy",
				totalMileage: Math.floor(Math.random() * 19000) + 10000,
				unit: "km"
			}
		},
		{
			data: {
				id: "2",
				name: "MaxMobil",
				totalMileage: Math.floor(Math.random() * 19000) + 10000,
				unit: "km"
			}
		},
		{
			data: {
				id: "3",
				name: "Odin",
				totalMileage: Math.floor(Math.random() * 19000) + 10000,
				unit: "km"
			}
		},
		// {
		// 	data: {
		// 		id: "4",
		// 		name: "Crazy Horse",
		// 		totalMileage: Math.floor(Math.random() * 19000) + 10000,
		// 		unit: "km"
		// 	}
		// },
		// {
		// 	data: {
		// 		id: "5",
		// 		name: "Fast and Furious",
		// 		totalMileage: Math.floor(Math.random() * 19000) + 10000,
		// 		unit: "km"
		// 	}
		// }
	];
	// Splitting the vehicles array into chunks of 3
	const chunkedVehicles = chunkArray(vehicles, 3);


	function chunkArray(array: Vehicle[], size: number) {
		const chunkedArray = [];
		for (let i = 0; i < array.length; i += size) {
			chunkedArray.push(array.slice(i, i + size));
		}
		return chunkedArray;
	}

	return (
		<div className='flex flex-column gap-5 align-self-center align-items-center justify-content-center' >
			<div className='align-self-center'>
				<VehiclesListRangeSelector />
			</div>
			<div className='flex gap-5 text-2xl flex-grow-1 align-items-center justify-content-center flex-column lg:flex-row xl:flex-row md:flex-row p-3'>
				<div className='p-1 flex flex-column align-items-center justify-content-center gap-1'>
					{/* <i className='pi pi-map-marker text-2xl' /> */}
					<span className='font-bold text-base'>Total Mileage</span>
					<span className='text-primary'>{(35402).toLocaleString('de-DE')} km</span>
				</div>
				{/* <div className="ef-circle-divider" /> */}
				<Divider className='border-1 surface-border pt-3 pb-3' layout='vertical' />
				<div className='p-1 flex flex-column align-items-center justify-content-center gap-1'>
					{/* <i className='pi pi-money-bill text-2xl' /> */}
					<span className='font-bold text-base'>Total Cost</span>
					<span>{(24402).toLocaleString('de-DE')} €</span>
				</div>
				{/* <div className="ef-circle-divider" /> */}
				<Divider className='border-1 surface-border pt-3 pb-3' layout='vertical' />
				<div className='p-1 flex flex-column align-items-center justify-content-center gap-1'>
					{/* <i className='pi pi-bolt text-2xl' /> */}
					<span className='font-bold text-base'>Total Volts</span>
					<span>{(135402).toLocaleString('de-DE')} kw</span>
				</div>
			</div>
			<div className='pt-4 flex flex-column gap-6 align-items-center justify-content-start'>
				<div className='flex align-items-center justify-content-center flex-wrap flex-column' style={{ width: "fit-content" }}>
					{chunkedVehicles.map((vehicleChunk, index) => (
						<div key={index} className='flex align-self-start align-items-center justify-content-center xl:flex-row flex-column flex-wrap gap-3 '>
							{vehicleChunk.map((vehicle) => (
								<div key={vehicle.data.id}>
									<VehiclesDetailsCard
										name={vehicle.data.name}
										totalMileage={vehicle.data.totalMileage}
										unit={vehicle.data.unit}
										vehicleId={vehicle.data.id}
										selected={selectedVehicleId != undefined && selectedVehicleId == vehicle.data.id}
										onDetailsClick={(vehicleId?: string) => setSelectedVehicleId(vehicleId)}
									/>
								</div>
							))}
						</div>
					))}
				</div>
				<TabView  className='w-full'>
					<TabPanel header="Charging Sessions" className=''>
						<div>
							<VehicleMetricsChargingSessionsCard />
						</div>
					</TabPanel>
					<TabPanel header="Charging Scatter" className=''>
						<div>
							<VehicleMetricsChargingScatterCard />
						</div>
						
					</TabPanel>
				</TabView>
			</div>
			{/* <Dock
			className='pt-8 flex align-items-start justify-content-center'
			
			style={{height: "100%"}}
			model={[
				{
					separator: true,

					icon: () => <VehiclesDetailsCard />
				} as MenuItem,
				{
					separator: true,
					icon: () => <VehiclesDetailsCard />
				} as MenuItem,
				{
					separator: true,
					icon: () => <VehiclesDetailsCard />
				} as MenuItem
			]}>
			</Dock> */}
			{/* 
			<button onClick={addNewVehicle()} ></button> */}
		</div>
	);
}
