import type {IPublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'; // Icons
import 'primereact/resources/primereact.min.css'; // Core css
import 'src/assets/themes/ef-lara-light-theme.css'; // Theme
import {QueryClient, QueryClientProvider} from 'react-query';
import './App.css';
import {CoreRouter} from './core';

type AppProps = {
	pca: IPublicClientApplication;
};

const queryClient = new QueryClient();

function App({pca}: AppProps) {
	return (
		<MsalProvider instance={pca}>
			<QueryClientProvider client={queryClient}>
				<CoreRouter />				
			</QueryClientProvider>
		</MsalProvider>
	);
}

export default App;
