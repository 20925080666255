import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {InteractionType} from '@azure/msal-browser';
import {type PropsWithChildren} from 'react';
import {loginRequest, PageContainer, type PageContainerProps} from 'src/shared';

export function AuthPageContainer(props: PropsWithChildren<PageContainerProps>) {
	const {...pageContainerProps} = props;
	return (
		<MsalAuthenticationTemplate 
			interactionType={InteractionType.Redirect}
			authenticationRequest={loginRequest}					
		>
			<PageContainer {...pageContainerProps}>
				{props.children}
			</PageContainer>
		</MsalAuthenticationTemplate>
	);
}

export default AuthPageContainer;