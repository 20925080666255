
import { ChartData, ChartOptions } from 'chart.js';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Chart } from 'primereact/chart';
import { Vehicle } from '@/apis/api-client';


type VehicleMetricsChargingSessionsCardProps = {
	metrics?: any;
};

export function VehicleMetricsChargingSessionsCard({}: VehicleMetricsChargingSessionsCardProps) {

	const { isLoading, data, isError } = useQuery(
		['fooBars'],
		fetchFooBars,
		{ staleTime: 3000 },
	);

    const [chartData, setChartData] = useState<ChartData | undefined>({
		labels: ['Truckee, CA - Soaring Way', 'Deggendorf, Globus', 'Hengersberg, Shell', 'Passau, eternalfoo GmbH'],
		datasets: [
			{
				label: 'Charging Sessions',
				data: [23.18, 37.41, 12.67, 22.25],
				fill: false,
				borderColor: '#04252b',
				tension: 0.4,
				pointRadius: 8, // Adjust the point radius for thicker points
				pointHoverRadius: 16, // Adjust the hover radius for thicker points on hover
			}
		]
	});
    const [chartOptions, setChartOptions] = useState<ChartOptions | undefined>({
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: (value) => {
						return value + ' €';
					}
				}
			}
		},
		responsive: true,
		animation: {
            duration: 1000, // Animation duration in milliseconds
            easing: 'easeOutQuart' // Easing function for the animation (optional)
        },
		plugins: {
			tooltip: {
				callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y.toFixed(2)} €`;
                        }
                        return label;
                    }
                }
			}
		}
	});

	async function fetchFooBars(): Promise<Vehicle[] | undefined> {
		// const response = await VehiclesService.get(lazyParams?.first ?? 0, lazyParams?.rows ?? 10);
		// if (response.result) {
		// 	setTotalRecords(response.totalCount ?? 0);
		// 	return response.result;
		// }

		return undefined;
	}

	return (
        <div className="border-1 surface-border border-round">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
	);
}
