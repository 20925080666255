import {EventType, type AuthenticationResult, type Configuration, type EventMessage, type PopupRequest, type PublicClientApplication, type SilentRequest} from '@azure/msal-browser';
import axios, {type AxiosRequestConfig} from 'axios';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.REACT_APP_MSAL_API_CLIENT_ID ?? "",
		authority: process.env.REACT_APP_MSAL_API_AUTHORITY ?? "",
		redirectUri: '/',
		postLogoutRedirectUri: '/',
	},
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: process.env.REACT_APP_MSAL_API_SCOPES?.split(",") ?? [],
};

export function setupMsal(msalInstance: PublicClientApplication) {
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload !== null) {
			const payload = event.payload as AuthenticationResult;
			const {account} = payload;
			msalInstance.setActiveAccount(account);
		}
	});
}

export function setupApiBearerAuthentication(instance: PublicClientApplication) {

	axios.interceptors.request.use(
		async (config: AxiosRequestConfig) => {
			const accounts = instance.getAllAccounts();
			if (accounts.length > 0) {

				const silentRequest: SilentRequest = {
					scopes: loginRequest.scopes,
					account: accounts[0],
				};

				const accessTokenResponse = await instance.acquireTokenSilent(silentRequest);
				if (config.headers) {
					config.headers['Content-Type'] = 'application/json';
				}

				if (accessTokenResponse) {
					const accessToken = accessTokenResponse.accessToken;

					if (config.headers && accessToken) {
						config.headers.Authorization = 'Bearer ' + accessToken;
					}
				}
			}

			return config;
		},
		error => {
			void Promise.reject(error);
		},
	);
}