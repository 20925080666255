import {AuthPageContainer} from 'src/shared';
import {UserInfoGeneralCard} from 'src/shared/libraries/msal/user-info/components/';

export function UserInfoView() {

	return (
		<AuthPageContainer title='User-Info' >
			<UserInfoGeneralCard />
		</AuthPageContainer>
	);
}

export default UserInfoView;