
import { ChartData, ChartOptions } from 'chart.js';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Chart } from 'primereact/chart';
import { Vehicle } from '@/apis/api-client';


type VehicleMetricsChargingScatterCardCardProps = {
	metrics?: any;
};

export function VehicleMetricsChargingScatterCard({ }: VehicleMetricsChargingScatterCardCardProps) {

	const { isLoading, data, isError } = useQuery(
		['fooBars'],
		fetchFooBars,
		{ staleTime: 3000 },
	);

	const documentStyle = getComputedStyle(document.documentElement);

	const [chartData, setChartData] = useState<ChartData | undefined>({
		labels: ['Supercharger', 'Arbeitsstelle', 'Zuhause', 'Sonstige'],
		datasets: [
			{
				label: 'Charging Scatter',
				backgroundColor: [
					documentStyle.getPropertyValue('--cyan-900'),
					documentStyle.getPropertyValue('--cyan-800'),
					documentStyle.getPropertyValue('--cyan-700'),
					documentStyle.getPropertyValue('--cyan-600')
				],
				data: [60, 20, 13, 7],
			}
		]
	});
	const [chartOptions, setChartOptions] = useState<ChartOptions | undefined>({
		responsive: true,
		animation: {
			duration: 1000, // Animation duration in milliseconds
			easing: 'easeOutQuart' // Easing function for the animation (optional)
		},
		scales: {
			r: {
				beginAtZero: true,
				max: 100,
				min: 0,
				ticks: {
					callback: (value) => {
						return value + ' %';
					}
				}
			}
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || '';
						if (label) {
							label += ': ';
						}
						if (context.parsed.y !== null) {
							label += `${context.parsed.r.toFixed(2)} %`;
						}
						return label;
					}
				}
			}
		}
	});

	async function fetchFooBars(): Promise<Vehicle[] | undefined> {
		// const response = await VehiclesService.get(lazyParams?.first ?? 0, lazyParams?.rows ?? 10);
		// if (response.result) {
		// 	setTotalRecords(response.totalCount ?? 0);
		// 	return response.result;
		// }

		return undefined;
	}

	return (
		<div className="border-1 surface-border border-round" style={{maxHeight: "15rem !important"}}>
			<Chart type="polarArea" data={chartData} options={chartOptions}/>
		</div>
	);
}
