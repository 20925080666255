import {loginRequest} from 'src/shared';
import {useMsal} from '@azure/msal-react';
import {Button} from 'primereact/button';
import {type SignInSignOutButtonProps} from 'src/shared';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function SignOutButton(props: SignInSignOutButtonProps) {
	const {loginType, ...buttonProps} = props;
	const {instance} = useMsal();

	async function handleLogin() {
		if (loginType === 'popup') {
			await instance.logoutPopup(loginRequest);
		} else if (loginType === 'redirect') {
			await instance.logoutRedirect(loginRequest);
		}
	}

	return (
		<Button
			onClick={async () => {
				await handleLogin();
			}}
			text
			rounded
			icon={<FontAwesomeIcon icon={faUserCircle} />}
			{...buttonProps}
		/>
	);
}

export default SignOutButton;
