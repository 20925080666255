import {loginRequest} from 'src/shared';
import {type SignInSignOutButtonProps} from '@/shared';
import {useMsal} from '@azure/msal-react';
import {Button} from 'primereact/button';

export function SignInButton(props: SignInSignOutButtonProps) {
	const {loginType, ...buttonProps} = props;
	const {instance} = useMsal();

	async function handleLogin() {
		if (loginType === 'popup') {
			await instance.loginPopup(loginRequest);
		} else if (loginType === 'redirect') {
			await instance.loginRedirect(loginRequest);
		}
	}

	return (
		<Button
			onClick={async () => {
				await handleLogin();
			}}
			text
			icon="pi pi-sign-in"
			// label='Login'
			{...buttonProps}
		/>
	);
}

export default SignInButton;
