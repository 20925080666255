import {useIsAuthenticated} from '@azure/msal-react';
import {Button, type ButtonProps} from 'primereact/button';
import {useNavigate} from 'react-router-dom';
import './NavigationItem.scss';

export type NavigationItemProps = {
	navigationUri: string;
	needsAuthentication?: boolean;
} & ButtonProps;

export function NavigationItem(props: NavigationItemProps) {
	const {navigationUri, needsAuthentication = false, ...buttonProps} = props;
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	return (
		<>
			{ ((needsAuthentication && isAuthenticated)  || !needsAuthentication)
			&&
			<Button
				text
				onClick={() => {					
					navigate(navigationUri);
				}}
				className='navigation-item'
				{...buttonProps}
			/>
			}
		</>
	);
}

export default NavigationItem;
