import { type PropsWithChildren } from 'react';
import { TheNavigator } from 'src/core';
import logo from 'src/assets/images/eternalfoo_text_darkgreen_gradient@4x.png';
import { useNavigate } from 'react-router-dom';

export type AppBarProps = Record<string, unknown>;

export function AppBar(props: PropsWithChildren<AppBarProps>) {

	const navigate = useNavigate();

	return (
		<div className='flex flex-column '>
			<div className='flex sticky gap-5 align-items-center' >
				<div className="p-4" style={{width: "20%"}}>
					<img
						src={logo}
						height="15rem"
						className='align-self-center' onClick={() => {
							navigate('/');
						}}
					/>
				</div>
				<div className='pr-4 flex-grow-1'>
					<TheNavigator />
				</div>
			</div>
			<div className='p-3'>
				{props.children}
			</div>
		</div>
	);
}

export default AppBar;
