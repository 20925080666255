
import {InteractionStatus} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {type ButtonProps} from 'primereact/button';
import {SignInButton, SignOutButton} from 'src/shared';

export type SignInSignOutButtonProps = {
	loginType: 'redirect' | 'popup';
} & ButtonProps;

export function SignInSignOutButton(props: SignInSignOutButtonProps) {
	const {loginType, ...buttonProps} = props;
	const {inProgress} = useMsal();
	const isAuthenticated = useIsAuthenticated();

	return (
		<>
			{
				isAuthenticated
					? <SignOutButton loginType={loginType} {...buttonProps} />
					: inProgress !== InteractionStatus.Startup
						&& inProgress !== InteractionStatus.HandleRedirect
						?
						<SignInButton loginType={loginType} {...buttonProps} />
						:
						<></>
			}
		</>
	);
}

export default SignInSignOutButton;
