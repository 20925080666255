import {useMsal} from '@azure/msal-react';
import {NavigationItem} from 'src/core';
import {SignInSignOutButton} from 'src/shared';

export function TheNavigator() {
	const {accounts} = useMsal();

	return (
		<div className='flex flex-grow-1 align-items-center gap-4'>
			<div className='flex justify-content-start flex-grow-1 justify-content-center'>
				<NavigationItem navigationUri='/dashboard' label='Dashboard' />
				<NavigationItem navigationUri='/foobars' label='FooBars' needsAuthentication />
			</div>
			<div className='flex justify-content-end' style={{width: "25%"}}>
				<NavigationItem label={accounts[0] ? accounts[0].name : ''} navigationUri='/users/user-info' needsAuthentication />
				<SignInSignOutButton loginType='redirect' />
			</div>
		</div>
	);
}

export default TheNavigator;
