import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// MSAL imports
import {PublicClientApplication} from '@azure/msal-browser';
import {msalConfig, setupApiBearerAuthentication, setupMsal} from 'src/shared';
import {OpenAPI} from './apis/api-client';
import {initializeLocaleSettings} from './shared';

export const msalInstance = new PublicClientApplication(msalConfig);
OpenAPI.BASE = process.env.REACT_APP_API_URL ?? '';

setupMsal(msalInstance);
setupApiBearerAuthentication(msalInstance);
initializeLocaleSettings();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App pca={msalInstance} />
	</React.StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
